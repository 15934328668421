.art-piece {
  .picker {
    padding-left: 0;
    padding-top: 8rem;
    padding-bottom: 21.3vh;
    height: calc(100vh - var(--gutter));
    padding-top: 18vh;
  }
}

.main-image {
  padding-top: 18vh;
  padding-bottom: 9vw;

  .main-image-inner {
    display: flex;
    flex-wrap: wrap;

    .image {
      position: relative;

      .overlay {
        height: 100%;
        width: 100%;
        background-color: white;
        position: absolute;
        left: 0;
        top: 0;
        transform-origin: bottom;
      }
      img {
        padding-bottom: 0.5px;
        margin-right: var(--gutter);
        max-height: 540px;
        max-width: calc(100vw - var(--gutter) * 2);
        width: auto;
        object-fit: contain;
      }

      .open-modal-btn {
        opacity: 0;
        position: absolute;
        bottom: 10px;
        right: calc(10px + var(--gutter));
        background-color: rgba(255, 255, 255, 0.821);
        transition: opacity 0.5s, background-color 0.5s;
      }

      img:hover + .open-modal-btn {
        opacity: 1;
      }

      .open-modal-btn:hover {
        opacity: 1;
        background: rgb(255, 255, 255);
      }
    }

    .info {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;

      .info-inner {
        margin-top: 1.4em;
        margin-bottom: 1.5em;

        .title-container {
          overflow: hidden;
          // border: 1px solid black;
          width: max-content;
          margin-bottom: 1em;

          h1 {
            margin: 0;
            line-height: 0.8;
          }
        }

        .p-container {
          opacity: 0;
          overflow: hidden;
          // border: 1px solid black;
          width: max-content;
          margin-bottom: 0.1em;

          p {
            margin: 0;
            line-height: 1.3em;
          }
        }
      }
    }
  }
}

.detail {
  padding-bottom: 9vw;
  // opacity: 0;
  .detail-inner {
    .spacer {
      height: 3vh;
    }
    .detail-content {
      .detail-image {
        display: flex;
        justify-content: flex-end;
        justify-content: flex-start;

        .detail-image-inner {
          // padding-left: var(--gutter);
          h4 {
            margin-bottom: 0.3em;
          }
          .image {
            position: relative;

            .overlay {
              background-color: white;
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 100;
              transform-origin: bottom;
            }

            img {
              max-height: 600px;
              max-width: calc(100vw - var(--gutter) * 2);
              width: auto;
              min-height: 450px; // stop image collapsing to 0 with min-height
              min-height: 292px; // stop image collapsing to 0 with min-height
              object-fit: contain;
              padding: 0.5px;
            }
          }
        }
      }
    }
  }
}

.art-piece-picker {
  position: relative;
}

// TABLETS

// SMALL SCREENS, LAPTOPS - from min-width: 769px

@media screen and (min-width: 746px) {
  .art-piece {
    padding-right: 10vw;
  }
  .main-image {
    .main-image-inner {
      .image {
        img {
          // max-width: calc(500px - var(--gutter) * 2); // constrain landscape
          min-height: 450px; // stop image collapsing to 0 with min-height
          // min-height: 540px;
        }
      }
    }
  }
  .detail {
    .detail-inner {
      .detail-content {
        .detail-image {
          justify-content: center;
          overflow: hidden;
          .detail-image-inner {
            .image {
              img {
                min-height: 450px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 901px) {
  .main-image {
    .main-image-inner {
      .image {
        img {
          max-width: 815px; // constrain landscape
          max-height: 538px; // constrain portrait
        }
      }

      .info {
        .info-inner {
          width: 70%;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .main-image {
    .main-image-inner {
      .image {
        img {
          max-width: 780px;
          min-height: 500px;
        }
      }
    }
  }
  .detail {
    .detail-inner {
      .detail-content {
        .detail-image {
          .detail-image-inner {
            .image {
              img {
                max-width: 780px;
                min-height: 500px;
              }
            }
          }
        }
      }
    }
  }
}

// was 1441px
// @media screen and (min-width: 1600px) {
//   .main-image {
//     .main-image-inner {
//       .image {
//         img {
//           // max-width: 600px; // constrain landscape
//           max-height: 700px; // constrain portrait
//           min-height: 500px; // constrain prior to loading image
//         }
//       }
//     }
//   }
//   .detail {
//     .detail-inner {
//       .detail-content {
//         .detail-image {
//           .detail-image-inner {
//             .image {
//               img {
//                 max-width: 780px; // constrain landscape
//                 max-height: 600px; // constrain portrait
//                 min-height: 500px; // constrain prior to loading image
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

@media screen and (min-width: 1600px) {
  .main-image {
    .main-image-inner {
      .image {
        img {
          max-width: 906px;
          max-height: 700px;
          min-height: 600px;
        }
      }
      .info {
        display: flex;
        align-items: flex-end;

        .info-inner {
          width: 100%;
        }
      }
    }
  }
  .detail {
    .detail-inner {
      .detail-content {
        .detail-image {
          .detail-image-inner {
            .image {
              img {
                max-width: 780px; // constrain landscape
                max-height: 700px; // constrain portrait
                min-height: 600px; // constrain prior to image loading
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 2000px) {
  .main-image {
    .main-image-inner {
      .image {
        img {
          max-width: 1058px; // constrain landscape
          max-height: 800px; // constrain portait
          min-height: 700px; // constrain prior to image loading
        }
      }
    }
  }
  .detail {
    .detail-inner {
      .detail-content {
        .detail-image {
          .detail-image-inner {
            .image {
              img {
                max-height: 800px;
              }
            }
          }
        }
      }
    }
  }
}
