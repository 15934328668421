.modal-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--gutter);

  .image {
    height: 100%;
    width: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.close-modal-btn {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;

  span {
    color: lightgrey;
    text-decoration: underline;
  }
}
