* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--primary);
  line-height: 1em;
}

body {
  overscroll-behavior: none;
}

p,
label,
ul li span {
  line-height: 1.3em;
}

:root {
  --indent: ;
  --primary: hsl(0, 0%, 20%);
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
.link,
.links a,
.logo,
.btn,
.mailto,
.copyright,
label,
input,
button,
ul,
li {
  font-size: 0.9rem;
}

ul {
  list-style-type: none;
  text-decoration: none;
}

li {
  display: inline-block;
}

a,
.link,
button,
.c-link {
  text-decoration: none;
  cursor: pointer;
}

a {
  display: inline-block;
}

img {
  display: block;
}

.btn {
  cursor: pointer;
  padding: 0.8em 1.5em;
  border-radius: 1000px;
  border: solid 1px var(--primary);
  display: inline-block;
}

.z-1 {
  position: fixed;
  z-index: 1;
}

.z-2 {
  position: relative;
  z-index: 100;
}

/* Utils */

.uppercase {
  text-transform: uppercase;
}

.lg {
  --lg: 1.8rem;
  font-size: var(--lg);
  line-height: 1em;
}

.sm {
  --sm: 1.6rem;
  font-size: var(--sm);
}

.gutter {
  --gutter: 4vw;
  padding: var(--gutter) var(--gutter) 0 var(--gutter);
  /* height: calc(100vh - var(--gutter)); */
}

.indent {
  --indent: 0%;
  padding-left: var(--indent);
}

.footer-height {
  --footer-height: 550px;
  height: var(--footer-height);
}

/* Collection Title */
.g-collection-title {
  position: absolute;
  top: -3px;
  width: 15em;
}

@media screen and (min-width: 746px) {
  .g-collection-title {
    top: -6px;
  }
}

@media screen and (min-width: 768px) {
  .g-collection-title {
    width: 21em;
  }
}

@media screen and (min-width: 1000px) {
  .g-collection-title {
    width: unset;
  }
}

/* Page-overlay for transitions */
.page-overlay {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  transform: scaleY(0);
  transform-origin: bottom;
}

/* Copyright */
.copyright {
  position: absolute;
  right: var(--gutter);
  bottom: var(--gutter);
}

/* MEDIA QUERIES */

@media screen and (min-width: 768px) {
  .gutter {
    --gutter: 3vw;
  }
  .lg {
    --lg: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  .gutter {
    --gutter: 2vw;
  }
  .indent {
    --indent: 12%;
  }
  .footer-height {
    --footer-height: 360px;
  }
}

@media screen and (min-width: 1440px) {
  .gutter {
    --gutter: 1.5vw;
  }
}
