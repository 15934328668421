.bio {
  .bio-inner {
    height: 100%;

    .jenni-cadman {
      margin-top: 18vh;
      flex-wrap: wrap;

      .image {
        .image-wrapper {
          width: calc(100vw - var(--gutter) * 2);
          position: relative;

          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            z-index: 100;
            transform-origin: bottom;
          }

          img {
            height: 95%;
            width: 100%;
            object-fit: cover;
            filter: grayscale(1) brightness(1.2) contrast(1.1);
          }
        }
      }
      .credentials {
        padding-top: 1.5rem;
        flex: 1;
        order: 3;

        .credentials-inner {
          // display: flex;

          p {
            display: block;
          }
        }
      }
      .info {
        order: 2;
        padding-top: 1.5rem;

        .info-inner {
          p {
            position: relative;
            // overflow: hidden;
            margin: 0;
          }

          p > div {
            position: relative;
            margin-right: 0.4em;
          }

          .split-child {
            line-height: 1.3em;
            display: inline-block;
            overflow: hidden;
            will-change: transform;
          }

          p:not(:last-child) {
            margin-bottom: 1em;
          }
        }
      }
    }

    .experience {
      padding-top: 10em;
      padding-bottom: 10em;

      .category {
        flex-direction: column;
        display: flex;

        &:not(:last-child) {
          margin-bottom: 10em;
        }
        .heading {
          width: 50%;
          max-width: 14em;
          margin-bottom: 1.5em;

          h2 {
            max-width: 276px;
          }
        }

        ul {
          li {
            display: flex;
            & > div {
              display: inline-block;
            }

            &:not(:last-child) {
              margin-bottom: 0.5em;
            }

            .date {
              margin-right: 14em;
              width: 2em;
            }
            .details {
              line-height: 1.3;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .bio {
    .bio-inner {
      margin: auto;

      .jenni-cadman {
        display: flex;

        .image {
          display: flex;
          justify-content: flex-end;
          max-width: 430px;
          max-height: 430px;
          flex-basis: 50%;
        }

        .info {
          padding-top: 0;
          flex-basis: 40%;
          flex-grow: 1;
          flex-shrink: 1;

          .info-inner {
            padding-left: var(--gutter);
            // padding-right: var(--gutter);
          }
        }
      }

      .experience {
        .category {
          display: flex;
          flex-direction: row;

          &:not(:last-child) {
            margin-bottom: 10em;
          }
          .heading {
            width: 50%;
            max-width: 450px;
            h2 {
              max-width: 350px;
            }
          }

          ul {
            width: 36%;
            padding-left: var(--gutter);

            li {
              display: flex;
              & > div {
                display: inline-block;
              }

              &:not(:last-child) {
                margin-bottom: 1em;
              }

              .date {
                margin-right: 4em;
                width: 2em;
              }
              .details {
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1000px) {
  .bio .bio-inner .jenni-cadman {
    .info {
      flex-basis: 50%;
    }
  }
}
@media screen and (min-width: 1100px) {
  .bio .bio-inner .jenni-cadman {
    .image {
      max-width: 460px;
      max-height: 460px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .bio .bio-inner .jenni-cadman {
    padding-right: 12%;

    .image {
      max-width: 480px;
      max-height: 480px;
    }
    .info {
      flex-basis: 45%;
    }
  }
}

@media screen and (min-width: 1530px) {
  .bio .bio-inner .jenni-cadman {
    .credentials {
      flex-basis: 4%;
      display: flex;
      justify-content: center;
      .credentials-inner {
        justify-self: center;
        padding-left: var(--gutter);
        padding-right: var(--gutter);
      }
    }

    .info {
      flex-basis: 10%;
      padding-top: 0;
      // max-width: 550px;
      order: 3;
    }
  }
}

@media screen and (min-width: 1600px) {
  .bio .bio-inner .jenni-cadman {
    .image {
      max-width: 500px;
      max-height: 500px;
    }
  }
}

@media screen and (min-width: 1801px) {
  .bio .bio-inner .jenni-cadman {
    .credentials {
      .credentials-inner {
        // justify-self: center;
        // padding-left: var(--gutter);
        // padding-right: var(--gutter);
      }
    }
    .info {
      max-width: 550px;
    }
  }
}
