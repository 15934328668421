.collection {
  height: 100%;
  // height: calc(100vh - var(--gutter) * 2);
  width: 100%;
  position: relative;
  top: 0;

  .collection-inner {
    height: 100%;
    width: 100%;
    padding-top: 18vh;
  }
}

@media screen and (max-width: '649px') {
  .g-collection-title {
    visibility: hidden;
  }
}
