.loading-counter {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  font-size: 4rem;

  .underlay {
    width: 0%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    background-color: rgb(0, 0, 0);
    transform: translateY(-50%);
    // transform: scaleY(0.001);
  }

  .container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    font-size: 4rem;
    z-index: 100;

    .inner-container {
      width: 3em;
      height: max-content;
      background-color: rgb(255, 255, 255);
      display: flex;
      justify-content: center;

      .counter {
        text-align: center;
      }

      span {
        width: 100%;
        font-size: 4rem;
      }
    }
  }

  .overlay {
    position: absolute;
    bottom: 0;
    height: 0vh;
    width: 100%;
    background-color: rgb(139, 139, 139);
    z-index: 200;
  }
}
