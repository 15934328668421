.pages {
  height: 100%;
  width: calc(100%);
  min-height: 100vh;
  background-color: #fffcf5;
  background-color: white;
}

.footer-spacer {
  pointer-events: none;
}
