.picker {
  width: 100%;
}

.picker-inner {
  position: relative;
  height: max-content;

  .back-link-container {
    position: absolute;
    top: -1.5em;

    // overflow: hidden;
    width: max-content;
    margin-bottom: 0.1em;

    .back {
      text-decoration: underline;
      margin: 0;
      line-height: 1.4em;
    }
  }
  .artpiece-title {
    height: 1.1em;
    position: absolute;
    bottom: -1.3em;
    z-index: 10;

    .artpiece-title-container {
      overflow: hidden;
      width: max-content;
      max-width: 70vw;
      margin-bottom: 0.1em;
      position: absolute;

      h2 {
        margin: 0;
        line-height: 0.9em;
      }
    }
  }
  .counter {
    position: absolute;
    bottom: -0.3em;
    z-index: 2;
    pointer-events: none;

    p {
      position: absolute;
      right: 0.5rem;
      font-size: 1.1em;
    }
  }

  .picker-viewport {
    overflow-x: clip;
    overflow-y: visible;
    left: 0;
    position: relative;
    // opacity: 0;

    .image-menu {
      position: absolute;
      display: flex;
      flex-flow: row no-wrap;
      overflow-y: visible;
      overflow-x: clip; // HERE

      .image-item {
        padding-left: 0.1vw;
        padding-right: 0.1vw;
        position: relative;
        cursor: pointer;
        opacity: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border: rgb(132, 132, 132) 0.01vw solid;
        }

        .view {
          display: flex;
          align-items: center;
          position: absolute;
          bottom: 0.5em;
          // left: 0.8em;
          right: 0.8em;
          color: rgb(30, 30, 30);
          background-color: #ffffffd7;
          transition: background-color 0.25s ease;

          &:hover {
            background-color: rgb(241, 241, 241);
          }
        }
      }
    }
  }
}

.image-item {
  display: inline-block;
  height: 100%;
  width: 7.1428571429%;
}

// *******************
// TITLE MEDIA QUERIES
// *******************

// 0
// 399
// 457
// 514
// 571
// 628
// 685
// 742
// 1399
