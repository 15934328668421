.contact {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;

  .contact-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 330px;
    margin-left: var(--gutter);

    form {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 1em;
      }

      input {
        background-color: transparent;
        border: none;
        padding: 0.4em 0;
        position: relative;

        &:focus {
          outline: none;
        }
      }

      button {
        font-size: inherit;
        text-align: left;
        background-color: transparent;
        border: none;
        border-top: 1px solid black;
        padding: 0.4em 0;
        display: flex;
        align-items: center;

        span {
          margin-right: 0.3em;
          transition: 0.3s ease;
        }

        &:hover {
          span {
            margin-right: 0.6em;
          }
        }
      }

      .error,
      .success {
        margin-top: 20px;
      }
      .error {
        color: red;
      }
      .success {
        color: green;
      }
    }

    .enquiries {
      margin-top: -20px;
      a {
        text-decoration: underline;
      }
    }

    .socials {
      .socials-inner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span {
          position: relative;
          width: max-content;
          margin-bottom: 0.8em;

          &:last-child {
            margin-bottom: 0;
          }

          &::after {
            content: '';
            display: inline-block;
            height: 1px;
            width: 0%;
            background-color: black;
            position: absolute;
            bottom: -0.2em;
            left: 0;
            transition: width 0.3s;
          }

          &:hover::after {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .contact .contact-inner {
    height: 250px;
    .socials div.socials-inner {
      flex-direction: row;

      span {
        margin-bottom: 0;
      }
    }
  }
}
