.navbar {
  height: max-content;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 900;
  width: calc(100vw);
  align-items: center;

  .logo {
    cursor: pointer;

    .logo-link.visibility {
      visibility: hidden;
    }
  }

  nav {
    display: flex;

    .links {
      display: flex;
      align-items: center;
      position: relative;

      .nav-item {
        pointer-events: none;
        opacity: 0;
        position: absolute;
        right: 0.7rem;
        transition: right 0.5s, opacity 0.5s ease-in;
      }

      .nav-item.num {
        display: inline-block;
        opacity: 1;
      }

      .nav-item.current-path {
        pointer-events: unset;
        opacity: 1;
      }
    }

    .cross {
      height: 20px;
      width: 20px;
      position: relative;
      // background: grey;
      cursor: pointer;
      transform: rotate(0deg);
      transition: transform 0.4s ease;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        width: 100%;
        height: 1.5px;
        background: black;
      }
      span:first-child {
        transform: translate(-50%, -50%);
      }
      span:nth-child(2) {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  nav.open {
    .cross {
      transform: rotate(-135deg);
    }

    .links {
      .nav-item {
        pointer-events: unset;
      }
      .nav-item:first-child {
        opacity: 1;
        right: 9rem;
      }
      .nav-item:nth-child(2) {
        opacity: 1;
        right: 6.5rem;
      }
      .nav-item:nth-child(3) {
        opacity: 1;
        right: 1.3rem;
      }
      .nav-item:last-child {
        opacity: 0;
        right: 1.3rem;
      }

      .nav-item:not(.num):not(.current-path):hover {
        color: grey;
      }

      .nav-item.current-path {
        color: grey;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    .logo {
      .logo-link {
        visibility: hidden;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .navbar {
    .logo {
      .logo-link.visibility {
        visibility: visible;
      }
    }
  }
}
